#businessfinder {
  font-family: var(--bf-body-font-family);
  font-size: var(--bf-body-font-size);
  font-weight: var(--bf-body-font-weight);
  line-height: var(--bf-body-line-height);
  color: var(--bf-body-color);
  -webkit-text-size-adjust: 100%;
}

html{ font-size: 16px;}

body{ margin: 0;}

#businessfinder.app .container {
  width: 100%;
  max-width: 1230px;
  margin-left: auto;
  margin-right: auto;
}

#businessfinder.app .screen-reader-text{ border: 0; clip: rect(1px, 1px, 1px, 1px); clip-path: inset(50%); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; word-wrap: normal !important;}
#businessfinder.app .screen-reader-text:focus{ background-color: #eee; clip: auto !important; clip-path: none; color: #444; display: block; font-size: 1em; height: auto; left: 5px; line-height: normal; padding: 15px 23px 14px; text-decoration: none; top: 5px; width: auto; z-index: 100000;}

#businessfinder.app .form-control,
#businessfinder.app input[type="text"]{ padding: .375rem 1.25rem; line-height: 22px; height: 40px; min-height: 40px; background-color: var(--bf-white); border-color: var(--bf-border-color); border-radius: 0;}
#businessfinder.app .form-control:focus,
#businessfinder.app input[type="text"]:focus{ color: #000; background-color: #fff; border-color: var(--bf-primary); outline: 0; box-shadow: none;}
#businessfinder.app input::-webkit-outer-spin-button,
#businessfinder.app input::-webkit-inner-spin-button{ -webkit-appearance: none; margin: 0;}
#businessfinder.app input[type=number] { -moz-appearance: textfield;}
#businessfinder.app .form-control::-moz-placeholder,
#businessfinder.app input[type="text"]::-moz-placeholder { color: var(--bf-border-color); opacity: 1;}
#businessfinder.app .form-control::placeholder,
#businessfinder.app input[type="text"]::placeholder { color: var(--bf-border-color); opacity: 1;}

#businessfinder.app .form-check { padding-left: 1.625rem; margin-bottom: 0; display: block; min-height: 1rem; line-height: 1.125rem;}
#businessfinder.app .form-check-label{ line-height: 1.125rem; display: block; color: #666;}
#businessfinder.app .form-check-input[type=checkbox] { font-size: 1.125rem; margin-top: 0; border-color: var(--bf-border-color); border-radius: 0;}
#businessfinder.app .form-check-input[type=radio] { font-size: 1.125rem; margin-top: 0; border-color: var(--bf-border-color); border-radius: 50%;}
#businessfinder.app .form-check-input:active { filter: brightness(100%);}
#businessfinder.app .form-check-input:focus { border-color: var(--bf-border-color); outline: 0; box-shadow: none;}
#businessfinder.app .form-check-input:checked { background-color: var(--bf-primary); border-color: var(--bf-primary);}
#businessfinder.app .form-check-input:checked[type=checkbox] { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");}
#businessfinder.app .form-check-input:checked[type=radio] { background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");}
#businessfinder.app .form-check-input[type=checkbox]:indeterminate { background-color: var(--bf-primary); border-color: var(--bf-primary); background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");}
#businessfinder.app .form-check-input:disabled { pointer-events: none; filter: none; opacity: 0.5;}
#businessfinder.app .form-check-input[disabled] ~ .form-check-label,
#businessfinder.app .form-check-input:disabled ~ .form-check-label { opacity: 0.5;}

#businessfinder.app .btn{ padding: .5rem 1.5rem; margin: 0; font-family: var(--bf-body-font-family); font-size: var(--bf-body-font-size); font-weight: bold; line-height: 1.375rem; color: #000; text-transform: none; border-radius: 0;}
#businessfinder.app .btn:hover { color: #333;}
#businessfinder.app .btn-check:focus + .btn,
#businessfinder.app .btn:focus { outline: 0; box-shadow: none;}
#businessfinder.app .btn:disabled,
#businessfinder.app .btn.disabled,
#businessfinder.app fieldset:disabled .btn { pointer-events: none; opacity: 1;}
#businessfinder.app .btn-link { font-weight: 400; color: var(--bf-primary); text-decoration: none; }
#businessfinder.app .btn-link-lg{ font-size: var(--bf-body-font-size); font-weight: bold;}
#businessfinder.app .btn-link:hover { color: var(--bf-primary-hover); }
#businessfinder.app .btn-link:disabled,
#businessfinder.app .btn-link.disabled { color: var(--bf-primary-disabled);}
#businessfinder.app .btn-lg,
#businessfinder.app .btn-group-lg > .btn { padding: 1rem; font-size: 1.25rem; border-radius: 3px; }
#businessfinder.app .btn-md,
#businessfinder.app .btn-group-md > .btn { padding: .875rem; font-size: var(--bf-body-font-size); border-radius: 3px; }
#businessfinder.app .btn-sm,
#businessfinder.app .btn-group-sm > .btn { padding: 0.375rem .5rem; font-size: 0.8rem; line-height: .75rem; border-radius: 3px; }

#businessfinder.app .btn-primary,
#businessfinder.app .btn.btn-primary { color: #fff; background-color: var(--bf-primary); border-color: var(--bf-primary);}
#businessfinder.app .btn-primary:hover,
#businessfinder.app .btn-check:focus + .btn-primary,
#businessfinder.app .btn-primary:focus,
#businessfinder.app .btn-check:checked + .btn-primary,
#businessfinder.app .btn-check:active + .btn-primary,
#businessfinder.app .btn-primary:active,
#businessfinder.app .btn-primary.active,
#businessfinder.app .show > .btn-primary.dropdown-toggle { color: #fff; background-color: var(--bf-primary-hover); border-color: var(--bf-primary-hover); box-shadow: none;}
#businessfinder.app .btn-check:checked + .btn-primary:focus,
#businessfinder.app .btn-check:active + .btn-primary:focus,
#businessfinder.app .btn-primary:active:focus,
#businessfinder.app .btn-primary.active:focus,
#businessfinder.app .show > .btn-primary.dropdown-toggle:focus { box-shadow: none;}
#businessfinder.app .btn-primary:disabled,
#businessfinder.app .btn-primary.disabled { color: #fff; background-color: var(--bf-primary-disabled); border-color: var(--bf-primary-disabled);}

#businessfinder.app .btn-outline-primary,
#businessfinder.app .btn.btn-outline-primary { color: var(--bf-primary); border-color: var(--bf-primary);}
#businessfinder.app .btn-outline-primary:hover,
#businessfinder.app .btn-check:focus + .btn-outline-primary,
#businessfinder.app .btn-outline-primary:focus,
#businessfinder.app .btn-check:checked + .btn-outline-primary,
#businessfinder.app .btn-check:active + .btn-outline-primary,
#businessfinder.app .btn-outline-primary:active,
#businessfinder.app .btn-outline-primary.active,
#businessfinder.app .show > .btn-outline-primary.dropdown-toggle,
#businessfinder.app .btn-outline-primary.dropdown-toggle.show{ color: #fff; background-color: var(--bf-primary); border-color: var(--bf-primary); box-shadow: none;}
#businessfinder.app .btn-check:checked + .btn-outline-primary:focus,
#businessfinder.app .btn-check:active + .btn-outline-primary:focus,
#businessfinder.app .btn-outline-primary:active:focus,
#businessfinder.app .btn-outline-primary.active:focus,
#businessfinder.app .show > .btn-outline-primary.dropdown-toggle:focus,
#businessfinder.app .btn-outline-primary.dropdown-toggle.show:focus{ box-shadow: none;}
#businessfinder.app .btn-outline-primary:disabled,
#businessfinder.app .btn-outline-primary.disabled { color: var(--bf-primary-disabled); background-color: transparent;}

#businessfinder.app .app__header h1{ font-family: var(--bf-body-font-family); color: var(--bf-body-color); margin-bottom: 2rem; padding-top: 1.5rem;}

#businessfinder.app .dropdown > .btn-outline-primary.dropdown-toggle{ display: inline-block; padding-right: 2.375rem; padding-left: 1rem; right: 0; border-color: var(--bf-border-color); position: relative; border-radius: 0;}
#businessfinder.app .dropdown > .dropdown-toggle:after{ display: none;}
#businessfinder.app .dropdown .dropdown-toggle .icon{ font-size: 10px; position: absolute; right: .75rem; top: 50%; margin-top: -8px; line-height: 1; transform: rotate(0deg); width: auto; height: auto; transition: all .4s ease;}
#businessfinder.app .dropdown .dropdown-toggle.show .icon{ transform: rotate(180deg);}
#businessfinder.app .dropdown .dropdown-menu{ padding: 1rem 0; transform: translate(0px, 0) !important; top: 100% !important; bottom: auto !important; width: auto; max-height: 290px; overflow-x: hidden; overflow-y: auto; min-width: 11rem; box-shadow: none; overflow-wrap: normal; font-size: var(--bf-body-font-size); border-radius: 0;}
#businessfinder.app .dropdown #toggle-turnovers + .dropdown-menu{ white-space: nowrap;}
#businessfinder.app .dropdown .dropdown-menu .form-check{ padding-left: 2.625rem; padding-right: 1rem; font-weight: normal;}
#businessfinder.app .dropdown .dropdown-menu li + li .form-check{ margin-top: 1rem;}

#businessfinder.app table{ border: none;}
#businessfinder.app table th{ padding: .75rem; border-color: #D0D0D0; border-width: 0;}
#businessfinder.app table td{ padding: 1.25rem .75rem; color: #707070; border-color: #D0D0D0; border-width: 0;}
#businessfinder.app table th:first-child,
#businessfinder.app table td:first-child{ padding-left: 1.5rem;}
#businessfinder.app table th:last-child,
#businessfinder.app table td:last-child{ padding-right: 1.5rem; text-align: right;}
#businessfinder.app table tr.organizations__panel{ background-color: #fff;}
#businessfinder.app table tr.organizations__panel > td{ padding: 0; text-align: left;}
/*#businessfinder.app table .organizations__panel td{ padding: 0;}*/
#businessfinder.app table td .row-toggle{ padding: .125rem .5rem; min-width: 2.5rem; background-color: transparent; border: none; color: var(--bf-primary); outline: none; transform: rotate(90deg); transition: all .4s ease;}
#businessfinder.app table td .row-toggle[aria-expanded="true"]{ transform: rotate(270deg);}
#businessfinder.app table tr td.norecord { position: relative; overflow: visible; width: 100%; height: auto; text-align: center;}

#businessfinder.app .organization{ position: relative; min-height: 140px;}
#businessfinder.app .organization .loading{ position: absolute;}
#businessfinder.app .organization .loading:before{ display: none;}
#businessfinder.app .organization .organization__header{ padding-top: 2rem;}
#businessfinder.app .organization .organization__header .organization__title{ font-size: 1.5rem; font-family: var(--bf-body-font-family); line-height: 1; font-weight: 700; color: var(--bf-primary); margin-bottom: 0;}
#businessfinder.app .organization .organization__body{ padding: 2rem 2rem 0; margin-top: 1.75rem; background: #F5F5F5; display: inline-block; width: 100%; color: #000;}
#businessfinder.app .organization .organization__section{ background: #fff; border: 1px solid #EBEBEB; padding: 2rem; margin-bottom: 2rem;}
#businessfinder.app .organization .organization__section.organization__details{ padding: 0; border: none;}
#businessfinder.app .organization .organization__map-images{ border: 1px solid #EBEBEB; height: 100%; display: flex; flex-direction: column;}
#businessfinder.app .organization .organization__images{ height: 140px; padding: 1rem; text-align: center; display: flex; align-items: center;}
#businessfinder.app .organization .organization__images img{ width: auto; height: auto; max-width: 100%; max-height: 100%;}
#businessfinder.app .organization .organization__section.organization__details .organization__map-only{ height: 100%;}
#businessfinder.app .organization .organization__section.organization__details .organization__map-only .organization__map{ height: 100%; border: 1px solid #EBEBEB;}
#businessfinder.app .organization .organization__section.organization__details .organization__map-images .organization__map{ height: calc(100% - 140px);}
#businessfinder.app .organization .organization__section.organization__details .organization__map iframe{ display: inline-block; vertical-align: top; width: 100%; height: 100%; min-height: 300px; margin-bottom: 0;}
#businessfinder.app .organization .organization__section.organization__detail{ height: 100%; margin-bottom: 0;}
#businessfinder.app .organization__info{ line-height: 1.75; margin-bottom: 1.25rem; word-wrap: break-word; word-break: break-word;}
#businessfinder.app .organization__info + .organization__info:last-child{ margin-bottom: 0;}
#businessfinder.app .organization__info-title{ font-family: var(--bf-body-font-family); color: var(--bf-body-color); font-size: var(--bf-body-font-size); line-height: 1.75; font-weight: bold; margin-bottom: 0;}
#businessfinder.app .organization__info ul{ list-style: none;}
#businessfinder.app .organization__info a{ color: var(--bf-body-color);}
#businessfinder.app .organization__social ul{ display: flex; margin-top: .5rem;}
#businessfinder.app .organization__social ul li{ padding-right: .75rem;}
#businessfinder.app .icon-social{ width: 2rem; height: calc(2rem + 1px); display: inline-block; vertical-align: top; background-repeat: no-repeat; background-position: center center;}
#businessfinder.app .icon-facebook{ background-image: url(icons/icon-facebook.png);}
#businessfinder.app .icon-twitter{ background-image: url(icons/icon-twitter.png);}
#businessfinder.app .icon-instagram{ background-image: url(icons/icon-instagram.png);}
#businessfinder.app .icon-x{ background-image: url(icons/icon-x.png);}
#businessfinder.app .icon-youtube{ background-image: url(icons/icon-youtube.png);}

#businessflang-nameinder .pagination{ margin-bottom: 2rem;}
#businessfinder .pagination .pages{ padding: 0; margin: -.5rem 0 0;}
#businessfinder .pagination .pages .page{ margin-left: -1px; margin-top: .5rem;}
#businessfinder .pagination .pagination__link:hover,
#businessfinder .pagination .pagination__link.deactive-link{background-color: #FFFFFF !important; color:#2E67A0 !important; border-color:#dbdbdb !important;}
#businessfinder .pagination .page .pagination__link,
#businessfinder .pagination .page .pagination__link:focus,
#businessfinder .pagination .page .pagination__link:hover,
#businessfinder .pagination .page .pagination__link.current{ min-width: 2.25rem; height: 2.375rem; padding: .625rem .5rem; font-weight: 600; text-align: center;}
#businessfinder .pagination .page .pagination__link[disabled=""]{ color: #bbb; pointer-events: none;}

#businessfinder.app .vendor-count{ margin-top: .875rem; font-size: 1rem; line-height: 1.125rem; color: #707070;}

#businessfinder.app .app__footer{ line-height: 1; margin-bottom: 2rem;}
#businessfinder.app .app__footer a{ font-size: .625rem; color: #666; text-decoration: none;}
#businessfinder.app .lang-box{ margin-top: 2.25rem;}
#businessfinder.app .lang-name{ text-decoration: none; color: #000; font-weight: normal; cursor: pointer;}
#businessfinder.app .lang-name-selected{ font-weight: bold;}

#businessfinder.app .accordion{ border-top: 1px solid #EBEBEB;}
#businessfinder.app .accordion .accordion-item{ border: none; border-bottom: 1px solid #EBEBEB;}
#businessfinder.app .accordion .accordion-item .organization__info{ margin-bottom: 0;}
#businessfinder.app .accordion .accordion-item .accordion-button{ padding: .75rem 0; font-weight: bold; background-color: #fff; color: var(--bf-body-color); box-shadow: none;}
#businessfinder.app .accordion .accordion-item .accordion-button:after{ background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23212529%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");}
#businessfinder.app .accordion .accordion-item .accordion-body{ padding-top: .25rem; padding-left: 0; padding-right: 0;}
#businessfinder.app .accordion .accordion-item .accordion-body .organization__info-title{ display: none;}

/*.loader { border: 10px solid #f3f3f3; border-top: 10px solid #0D67A0; border-radius: 50%; width: 80px; height: 80px; animation: spin 1s linear infinite;}*/
/* Absolute Center Spinner */
#businessfinder.app .loading { position: fixed; z-index: 999; overflow: show; margin: auto; top: 0; left: 0; bottom: 0; right: 0; width: 70px; height: 70px;}
#businessfinder.app .norecord { position: fixed; z-index: 999; overflow: show; margin: auto; top: 0; left: 0; bottom: 0; right: 0; font-weight: bold; width: 200px; height: 150px;}

/* Transparent Overlay */
#businessfinder.app .loading:before { content: ''; display: block; position: fixed; top: 0; left: 0; width: 100%; height: 100%; background-color: rgba(255,255,255,0.5);}

/* :not(:required) hides these rules from IE9 and below */
#businessfinder.app .loading:not(:required) { font: 0/0 a; color: transparent; text-shadow: none; background-color: transparent; border: 0;}
#businessfinder.app .loading:not(:required):after { content: ''; display: block; font-size: 10px; width: 70px; height: 70px; margin-top: -0.5em; border: 7px solid #0D67A0; border-radius: 100%; border-bottom-color: transparent; -webkit-animation: spinner 1s linear 0s infinite; animation: spinner 1s linear 0s infinite;}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 1199px) {
  #businessfinder.app .container{ width: 100%; padding-left: 1.5rem; padding-right: 1.5rem;}
  /*#businessfinder.app .organizations__table{ width: 100%; overflow-x: auto; overflow-y: hidden;}*/
  #businessfinder.app .organization .organization__header{ padding-top: 1.5rem; padding-left: 1.5rem;}
  #businessfinder.app .organization .organization__body{ padding: 1.5rem 1.5rem 0; margin-top: 1.5rem; margin-left: -1.5rem; margin-right: -1.5rem; width: calc(100% + 3rem);}
  #businessfinder.app .organization .organization__section{ padding: 1.5rem; margin-bottom: 1.5rem;}
  #businessfinder.app .organization .organization__section.organization__details:not(:last-child){ margin-bottom: 0;}
  #businessfinder.app .organization .organization__section.organization__detail{ border-bottom: none;}
  #businessfinder.app .organization .organization__section.organization__details .organization__map,
  #businessfinder.app .organization .organization__section.organization__details .organization__map-images .organization__map{ height: 270px;}
  #businessfinder.app .organization .organization__section.organization__details .organization__map iframe{ min-height: 270px;}
  #businessfinder.app .organization .organization__images{ height: 110px;}
  #businessfinder.app .organization .organization__section.organization__about{ border-top: none; padding-top: 0;}
  #businessfinder.app .organization .organization__section.organization__about > .row{ display: none;}
}
@media (max-width: 991px) {
  #businessfinder.app .organization__info{ margin-bottom: 1rem;}
}
@media (max-width: 767px) {
  #businessfinder.app .container{ width: 100%; padding-left: 1.25rem; padding-right: 1.25rem;}
  #businessfinder.app .app__header h1{ margin-bottom: 1rem;}
  #businessfinder.app .lang-box{ margin-top: 1.5rem;}

  #businessfinder.app .organization-filters{ margin-bottom: 1rem;}

  #businessfinder.app table{ margin-bottom: 1rem;}
  #businessfinder.app .organization .organization__header{ padding-top: 1.25rem;}
  #businessfinder.app .organization .organization__body{ padding: 1.25rem 1.25rem 0; margin-top: 1rem; margin-left: -1.25rem; margin-right: -1.25rem; width: calc(100% + 2.5rem);}
  #businessfinder.app .organization .organization__section{ padding: 1.25rem; margin-bottom: 1.25rem;}
  #businessfinder.app .organization .organization__section.organization__details .organization__map,
  #businessfinder.app .organization .organization__section.organization__details .organization__map-images .organization__map{ height: 240px;}
  #businessfinder.app .organization .organization__section.organization__details .organization__map iframe{ min-height: 240px;}

  #businessfinder.app table th:first-child,
  #businessfinder.app table td:first-child{ width: 85%;}
  #businessfinder.app table th:last-child,
  #businessfinder.app table td:last-child{ width: 15%; padding-right: .5rem;}
  #businessfinder.app table th:not(:first-child):not(:last-child),
  #businessfinder.app table td:not(:first-child):not(:last-child){ display: none;}

  #businessfinder.app .app__footer{ margin-bottom: 1rem;}
}
@media (max-width: 575px) {
  #businessfinder.app .app__header h1{ margin-bottom: .5rem;}
  #businessfinder.app .lang-box{ margin-top: 0;}
  #businessfinder.app .dropdown > .btn-outline-primary.dropdown-toggle{ padding-right: 2rem; padding-left: .75rem; width: 100%; text-align: left;}
  #businessfinder.app .organization .organization__header .organization__title{ font-size: 1.25rem;}
  #businessfinder.app .organization .organization__body{ padding-bottom: 1.25rem;}
  #businessfinder.app .organization .organization__section{ margin-bottom: 0;}
  #businessfinder.app .organization .organization__map-images{ padding: 1.25rem; border-bottom: none; border-top: none;}
  #businessfinder.app .organization .organization__section.organization__details .organization__map-only .organization__map{ padding: 1.25rem; border-bottom: none; border-top: none;}
  #businessfinder.app .organization .organization__section.organization__details .organization__images.ologo-top{ border: 1px solid #EBEBEB;}
  #businessfinder.app .organization .organization__section.organization__detail{ border-top: none; padding-bottom: 0;}
  /*#businessfinder.app .organization .organization__section.organization__about{ padding-top: 1.25rem;}*/
}
@media (min-width: 1200px) {
  #businessfinder.app .organization-filters{ margin-bottom: 3rem;}

  #businessfinder.app .organization .organization__map-images{ border-right: none;}
  #businessfinder.app .organization .organization__images{ justify-content: center;}
  #businessfinder.app .accordion{ display: none;}
}